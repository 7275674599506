<template>
    <div class='h-full'>
        <NavBar
            :user='$store.state.user'
            :user-organizations='userOrganizations'
            :organization='organization'
            :help-url='$t("help-url")'
            :key='navBarKey'
        />
        <router-view :organization='organization' v-if='loaded' @update:organization='updateOrganization' />
        <div
            v-if='loaded && $route.name === "organization" && !userHasEnvironmentsAccess'
            class='container flex flex-col items-center justify-center h-full'
        >
            <CustomTitle class='mb-6 font-black text-center'>
                {{ $t('ttl-org-without-environment') }}
            </CustomTitle>
            <p class='text-center text-xl max-w-4xl mx-auto'>
                {{ $t('p-org-without-environment') }}
            </p>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { gql } from '@apollo/client/core';
    import Loader from '../loader.js';
    import NavBar from '@/components/NavBar';
    import notify from '@/notify.js';
    import CustomTitle from '@/components/Title';
    import bus from '@/bus';

    export default {
        name: 'OrganizationView',
        components: {
            NavBar,
            CustomTitle,
        },
        data () {
            return {
                loaded: false,
                organization: {},
                userOrganizations: [],
                navBarKey: 1,
            };
        },
        computed: {
            ...mapState({
                user: state => state.user,
            }),
            userHasEnvironmentsAccess () {
                return this.organization && this.organization.fiduciaries && this.organization.fiduciaries.length > 0;
            },
        },
        watch: {
            '$route.params.organizationId': async function (newValue) {
                await this.loadOrganization(newValue);
                this.environmentRedirect(this.$route.params);
            },
        },
        async beforeRouteUpdate (to, from, next) {
            // default redirect on route organization
            if (
                to.name === 'organization' &&
                to.params.organizationId === from.params.organizationId
            ) {
                await this.loadOrganization(to.params.organizationId);
                this.environmentRedirect(this.$route.params);
            }

            next();
        },
        async mounted () {
            // goal here is to load the list of organizations the user has access to, if they have access to multiple
            await this.loadOrganization(this.$route.params.organizationId);
            // goal here is to load the organization information and environment list
            if (this.$route.name === 'organization') {
                this.environmentRedirect(this.$route.params);
            }
            bus.on('environmentUpdate',  ({id, name}) => {
                this.organization.fiduciaries.find(fidu => fidu.id === id).name = name;
            });
        },
        methods: {
            async loadOrganization (accountantId) {
                const loader = Loader.start();
                // Check if user's organizations are fetched
                await this.loadUserOrganizations();
                // Query the right organization from the collection
                this.organization = this.userOrganizations.find(organization => organization.id === accountantId);
                // Not found? We can refresh ONCE
                if (!this.organization) {
                    notify.error(this.$t('ttl-err-no-access'));
                    this.$router.push('/');
                    return;
                }
                // this is a work around to force vue to update the NavBar
                this.navBarKey += 1;
                localStorage['selectedOrganizationId'] = this.organization.id;
                Loader.stop(loader);
                this.loaded = true;
            },
            async loadUserOrganizations () {
                try {
                    const organizationIds = this.$store.state.user.organizationIds;
                    const query = gql`
                              query allFiduciaryData($accountantIds: [String!]!) {
                                accountants: accountants(accountantIds: $accountantIds) {
                                  id
                                  enterpriseName
                                  enterpriseNumber
                                  address
                                  address2
                                  zip
                                  city
                                  representativeName
                                  representativeFunction
                                  fiduciaries {
                                    id
                                    name
                                  }
                                }
                              }
                              `;
                    const variables = {accountantIds: organizationIds};
                    const {data} = await this.$apollo.query({
                        query,
                        variables,
                    });
                    this.userOrganizations = data.accountants;
                } catch (e) {
                    notify.error(this.$t('ttl-err-no-access'));
                    this.$router.push('/');
                }
            },
            environmentRedirect (params) {
                if (this.userHasEnvironmentsAccess) {
                    const previouslySelectedFiduciaryId = localStorage['selectedEnvironmentId'];
                    let environmentId;
                    if (this.organization.fiduciaries.some(f => f.id === params.environmentId)) {
                        // priority to the environment set by the url, if it exists in the current org
                        environmentId = params.environmentId;
                    } else if (this.organization.fiduciaries.some(f => f.id === previouslySelectedFiduciaryId)) {
                        // if no params, use what has been saved locally, if it exists in the current org
                        environmentId = previouslySelectedFiduciaryId;
                    } else {
                        // otherwise, take the first of the organization
                        environmentId = this.organization.fiduciaries[0].id;
                    }
                    if (environmentId) {
                        this.$router.push(`/organization/${this.organization.id}/environment/${environmentId}`);
                    }
                }
            },
            updateOrganization (organization) {
                this.loadOrganization(organization.id);
            },
        },

    };
</script>
