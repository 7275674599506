<script setup>
import { ref, defineEmits } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery, useMutation } from '@vue/apollo-composable';
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { Form } from 'vee-validate';
import FormInput from '@/components/FormInput';
import notify from '@/notify';
import FormToggle from '@/components/FormToggle.vue';
import { useRouter } from 'vue-router';
import { t } from '@/i18n';
import Translated from '@/components/Translated';
import validate from '@/validate';

const router = useRouter();

const props = defineProps({
    organization: Object,
});

const emit = defineEmits(['update:organization']);

const edit = ref(false);
const form = ref(null);
const impactedCodaMandates = ref(false);
let organizationLinkedClient = null;

const handleEdit = () => {
    edit.value = true;
};

const handleCancel = () => {
    form.value.resetForm();
    edit.value = false;
};

const updateOrganizationSubmit = async (values) => {
    delete values.enterpriseNumber;
    delete values.hasBelgianVatNumber;

    try {
        const { mutate: updateOrganization } = await useMutation(gql`
            mutation UpdateOrganization($organizationId: String, $input: UpdateOrganizationInput) {
                updateOrganization(organizationId: $organizationId, input: $input) {
                    errors { code, detail, source { pointer } }
                    organization {
                        id
                        enterpriseName
                        enterpriseNumber
                        address
                        address2
                        zip
                        city
                        representativeName
                        representativeFunction
                        fiduciaries {
                            id
                            name
                        }
                    }
                }
            }
        `);
        const result = (await updateOrganization({organizationId: props.organization.id, input: values})).data.updateOrganization;

        if (result.errors?.length > 0) {
            validate.reportGQLFieldErrors(result.errors, form);
        }

        emit('update:organization', result.organization);
        notify.success(t('suc-organization-updated'));

        edit.value = false;

        // redirect if there is impacted CODA mandates
        if (impactedCodaMandates.value) {
            router.push({
                name: 'fidu-client-uid-info',
                params: {
                    organizationId: props.organization.id,
                    environmentId: organizationLinkedClient.fiduciaryId,
                    uid: organizationLinkedClient.id,
                },
                query: { showImpactedCodaMandatesPopup: true },
            });
        }
    } catch (e) {
        notify.error(t('err-unknown'));
    }
};

async function getOrganizationLinkedClient () {
    return new Promise((resolve, reject) => {
        const { result, onResult, onError } = useQuery(gql`
            query Clients($input: ClientSearchInput) {
                clients(input: $input) {
                    results {
                        id
                        fiduciaryId,
                    }
                }
            }
        `, {
            input: {
                enterpriseNumbers: [props.organization.enterpriseNumber],
            },
        });

        onResult(() => {
            if (result.value.clients.results.length > 0) {
                resolve(result.value.clients.results[0]);
            } else {
                resolve(null);
            }
        });

        onError((error) => {
            notify.error(t('err-unknown'));
            reject(error);
        });
    });
}

async function getImpactedCodaMandates (clientId) {
    return new Promise((resolve, reject) => {
        const { result, onResult, onError } = useQuery(gql`
            query getCodaMandate($clientId: String) {
                codaMandates(
                    clientId:$clientId,
                    excludeMandatesWithoutBankAccounts:true,
                    states: [
                        "available-online",
                        "prepared",
                        "sent-client",
                        "problem",
                    ]
                ) {
                    id
                }
            }
            `, {
                clientId,
            });

        onResult(() => {
            resolve(result.value.codaMandates);
        });

        onError((error) => {
            notify.error(t('err-unknown'));
            reject(error);
        });
    });
}

async function init () {
    try {
        // we need to know if this Organization has a bingo client (client sharing the same enterprise number)
        organizationLinkedClient = await getOrganizationLinkedClient();
        if (organizationLinkedClient) {
            // if so, is there any CODA mandates that could be impacted by the changes ?
            // in this case, we display a specific warning on edit, and redirect user to the bingo client at save
            const mandates = await getImpactedCodaMandates(organizationLinkedClient.id);
            impactedCodaMandates.value = mandates.length > 0;
        }
    } catch {
        notify.error(t('err-unknown'));
    }
}

init();
</script>

<template>
    <Form
        ref='form'
        @submit='updateOrganizationSubmit'
    >
        <header class='flex justify-between'>
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-organization-information') }}
            </CustomTitle>
            <div class='flex align-center'>
                <template v-if='!edit'>
                    <FriendlyButton
                        label='lbl-org-info-edit'
                        symbol='pencil'
                        :action='handleEdit'
                        square
                        extra-small
                        no-margin
                        id='org-information-edit'
                    />
                </template>
                <template v-else>
                    <FriendlyButton
                        label='btn-cancel'
                        type='reset'
                        :action='handleCancel'
                        symbol='times'
                        secondary
                        square
                        extra-small
                        no-margin
                        class='mr-2'
                    />
                    <FriendlyButton
                        label='btn-save'
                        type='submit'
                        symbol='check'
                        square
                        extra-small
                        no-margin
                    />
                </template>
            </div>
        </header>
        <div
            v-if='impactedCodaMandates && edit'
            class='text-white mb-3 px-4 py-2 bg-orange-300 inline-block rounded-md flex items-center mb-6'
            id='impacted-coda-mandates-warning'
        >
            <i class='fa fa-exclamation-triangle mr-3'></i>
            <Translated>
                <template #en>
                    <b>Attention</b>: there are CODA mandates that will be impacted when updating your organization info.
                </template>
                <template #nl>
                    <b>Opgelet</b>: er zijn CODA-mandaten die worden geimpacteerd wanneer je je organisatie info bijwerkt.
                </template>
                <template #fr>
                    <b>Attention</b>: la mise à jour des informations de votre organization aura un impact sur certains mandats CODA.
                </template>
            </Translated>
        </div>
        <content-box>
            <div class='grid grid-cols-2 gap-6'>
                <div>
                    <div class='grid grid-cols-1 gap-3'>
                        <FormInput
                            :value='organization.enterpriseName'
                            name='enterpriseName'
                            ref='enterpriseName'
                            :label='$t("lbl-enterprise-name")'
                            :placeholder='$t("lbl-enterprise-name")'
                            :edit='edit'
                            rules='required|max:160'
                            id='enterpriseName'
                        />
                        <FormInput
                            :value='organization.enterpriseNumber'
                            :label='$t("lbl-enterpriseNumber")'
                            id='enterpriseNumber'
                            name='enterpriseNumber'
                        />
                        <FormToggle
                            name='hasBelgianVatNumber'
                            :value='organization.hasBelgianVatNumber'
                            :label='$t("lbl-has-belgian-vat-number")'
                            disabled
                            id='hasBelgianVatNumber'
                            class='my-2'
                        />
                        <FormInput
                            :value='organization.address'
                            name='address'
                            :label='$t("lbl-address")'
                            :placeholder='$t("lbl-address-line-1")'
                            rules='required|max:108'
                            :edit='edit'
                            id='address'
                        />
                        <FormInput
                            :value='organization.address2'
                            name='address2'
                            rules='max:100'
                            :placeholder='$t("lbl-address-line-2")'
                            :edit='edit'
                            id='address2'
                        />
                        <FormInput
                            :value='organization.zip'
                            name='zip'
                            :label='$t("lbl-zip")'
                            :placeholder='$t("lbl-zip")'
                            rules='required|min:4|max:20'
                            :edit='edit'
                            id='zip'
                        />
                        <FormInput
                            :value='organization.city'
                            name='city'
                            :label='$t("lbl-city")'
                            :placeholder='$t("lbl-city")'
                            rules='required|max:50'
                            :edit='edit'
                            id='city'
                        />
                    </div>
                </div>
                <div>
                    <div class='grid grid-cols-1 gap-3'>
                        <FormInput
                            :value='organization.representativeName'
                            name='representativeName'
                            :label='$t("lbl-representative-name")'
                            :placeholder='$t("placeholder-representative-name")'
                            rules='required|max:160|composedName'
                            :edit='edit'
                            id='representativeName'
                        />
                        <FormInput
                            :value='organization.representativeFunction'
                            name='representativeFunction'
                            :label='$t("lbl-representative-function")'
                            :placeholder='$t("lbl-representative-function")'
                            :edit='edit'
                            id='representativeFunction'
                            rules='required|max:128'
                        />
                    </div>
                </div>
            </div>
        </content-box>
    </Form>
</template>
